//storage存储key
const STORAGE_KEYS = {
    tokenKey: "brrricher_token",
    userInfo: "userInfo",
    cartId: "cart_id",
    recentSearch: 'recent_search',
    carts: 'carts',
    cartVersion: 'cartVersion',
    beericher_config: 'beericher_config',
    chatWoot: 'chat_woot',
    authPage: 'authPage',
    locale: 'locale',
}

//常量字典
const CONST = {
    LOGIN_TYPE: {
        FACEBOOK: 'facebook',
        LINE: 'line',
        OTHER: 'other'
    }
}

//api地址
const SERVER = {
    TEST: "https://test.yfwl.xyz/appapi/",
    RELEASE: "https://xmf.yfwl.xyz/appapi"
}

// const BASE_API = process.env.NODE_ENV == 'development' ? SERVER.TEST : SERVER.RELEASE
const BASE_API = SERVER.RELEASE
// const BASE_API = "https://localhost:8080/appapi"

// SHOPIFY配置
const SHOPIFY = {
    URL: "https://beericher.myshopify.com/api/2022-04/graphql.json",
    STOREFRONT_TOKEN: '4a9305c45447782249361d3a4f5bec08'
}

//不同公司版本的变量，根据不同分支来处理
const COMPANY = "MX/BEERICHER"; // "MX/BEERICHER";

//Line Config
//Line 登录回调地址
const LOGIN_CALLBACK_HOST = {
    //https://10.201.52.68:8080/
    //https://10.202.100.228:8080/
    //https://192.168.31.217:8080/
    TEST: "https://10.201.52.68:8080",
    RELEASE: "https://m.beericher.com"
}

const LOGIN_CALLBACK = process.env.NODE_ENV == 'development' ? LOGIN_CALLBACK_HOST.TEST : LOGIN_CALLBACK_HOST.RELEASE
const LINE = {
    CLIENT_ID: "1656453207",
    LOGIN_CALLBACK,
    LOGIN_CHANNEL: 'line_web_app'
}

//FACEBOOK Config
const FACEBOOK = {
    appId: "456717405420216",
    version: 'v14.0',
    LOGIN_CALLBACK,
    LOGIN_CHANNEL: 'facebook_web_app'
}

//vendors 字典
const VENDOR = {
    ali1688: 'ali1688'
}

const SHOPIFY_TAG = {
    cross_border: 'cross_border',
    local: 'local',
    cross_border_to_ship: 'cross_border_to_ship',
    cross_border_to_receive: 'cross_border_to_receive'
}

const ORDER_TYPE = {
    toBeConfirmed: 'To be confirmed',
    toPay: 'To Pay',
    toShip: 'To Ship',
    toReceive: 'To Receive',
    cancelled: 'Cancelled',
    completed: 'Completed'
}

//PAYMENTITEM 字典
const PAYMENTITEM = {
    b2b_order: 'b2b_order',
    b2b_freight: 'b2b_freight',
}

//Success 字典
const SUCCESS = {
    SLIP_UPLOAD: 'slip_upload',
    ORDER: 'order',
    CANCEL_UNPAY: 'cancel_unpay',
    CANCEL_PAID_UNSHIP: 'cancel_paid_unship',
    CANCEL_PAID_SHIP: 'cancel_paid_ship',
}

const TIKTOK_PIXEL_ID = 'CK00QNBC77UDFRIV0O80'

export {
    STORAGE_KEYS,
    BASE_API,
    SHOPIFY,
    COMPANY,
    LINE,
    FACEBOOK,
    CONST,
    VENDOR,
    SHOPIFY_TAG,
    ORDER_TYPE,
    PAYMENTITEM,
    SUCCESS,
    LOGIN_CALLBACK_HOST,
    TIKTOK_PIXEL_ID
}
