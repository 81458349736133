export default {
    "没有更多了": "End of list",
    "加载中...": "Loading...",
    "复制成功": "Copied",
    "确认": "Confirm",
    "确定": "Confirm",
    "取消": "Cancel",
    "暂无数据": "No data",
    "查件": "Order check",
    "客服": "Customer service",
    "我的": "Profile",
    "搜索快递单号": "Search for the tracking number",
    "搜索": "Search",
    "发往泰国(total)": "Ship to Thailand({total})",
    "发往泰国": "Shipping to Thailand",
    "点击支付 >>": "{total} parcels arrived and ready to be delivered to your door. Click to pay >>",
    "暂无发往泰国运单": "Temporary no waybills",
    "派送上门(total)": "Delivering ({total})",
    "派送上门": "Delivering",
    "暂无派送上门运单": "No delivery waybill",
    "已签收": "Signed",
    "件": "Package",
    "暂无已签收运单": "No waybill has been signed yet",
    "小蜜蜂转运优惠券": "Tukship Coupon",
    "券": "Coupon",
    "折": "Discount",
    "无门槛": "No conditions",
    "满可用": "Available for {amount} baht",
    "领取后请到[我的-优惠劵]查看": "Please go to [My-Discount Voucher] to view after receiving",
    "填写收件地址后可以领取优惠券": "You can receive the coupon after filling in the receiving address",
    "一键领取": "Grab it now!",
    "前往填写收件地址": "Go to fill in your address",
    "您已成功领取": "Coupon claimed",
    "领取失败,稍后再试": "Failed to claim, try again later",
    "客服微信": "Customer Service WeChat",
    "[点击复制]": "[Copy]",
    "语言切换": "Language",
    "使用说明": "How it works",
    "时效与价格": "Lead time and pricing",
    "热门问题": "Top Questions",
    "什么不能寄？": "What can't be sent?",
    "证件例如身份证毕业证，烟，酒，易燃易爆物品，危险化学品，生鲜品，植物及种子，动物等违反中泰两国法律的物品。": "Credentials such as ID cards, cigarettes, alcohol, flammable and explosive materials, hazardous chemicals, fresh products, plants and seeds, animals and other items that violate the laws of China and Thailand.",
    "丢件或损毁如何赔付？": "How are we compensate for lost or damaged goods?",
    "未保价的包裹按运费三倍赔偿，保价后按保价金额赔偿，贵重物品请务必预报运单并保价。": "Orders that are not insured will be compensated three times the freight rate, and after insuring, they will be compensated according to the insured amount. For valuable items, please be sure to choose the additional services to insure the goods.",
    "如何付运费？": "How to pay for freight service?",
    "包裹到达泰国后，系统将给您发送运费通知，支付后系统安排配送包裹上门。": "After the package arrives in Thailand, the system will send you a payment notification. After payment, the system will arrange the freight delivery to your door.",
    "是否有最低重量要求？": "Is there a minimum weight requirement?",
    "单批包裹不满一公斤按一公斤计费，例如您有两个包裹到达泰国仓库，如果两个包裹的总重量不足一公斤, 按一公斤计费。如果您还有包裹在路上，先到的包裹可以暂存泰国仓，等到齐之后一起计费付款，再派送上门。": "A single batch of parcels less than one kilogram will be charged as one kilogram. For example, if you have two parcels arriving at a Thai warehouse, if the total weight of the two parcels is less than one kilogram, they will be charged as one kilogram. If you still have parcels on the way, the first parcel can be temporarily stored in the Thai warehouse, and the bill will be charged and paid when all the parcels arrive before being delivered to your door.",
    "用户代码": "User code",
    "泰国收件地址": "Account",
    "点击填写收件地址": "Click to fill in the receiving address",
    "预报运单": "Additional services",
    "退回/保价/打木架/到付": "Insurance/Wooden Frame/Pay on Delivery/Reject",
    "优惠券": "Coupon",
    "转运仓信息": "Recipient-on-behalf information",
    "收件人": "Recipient",
    "电话": "Mobile",
    "地址": "Address",
    "[复制转运仓信息]": "[Copy information]",
    "张": "piece",
    "无可用券": "No coupons available",
    "请您先填写泰国收件地址": "Please fill in the local delivery address",
    "单号": "Tracking number",
    "[编辑备注]": "Edit notes",
    "[复制单号]": "Copy tracking number",
    "增值服务": "Additional services",
    "共铢(不含到付)": "a total of {amount} baht (not including payment on delivery)",
    "等待包裹入库": "Waiting for the parcel to arrive at GZ warehouse",
    "重量": "Weight",
    "weight 公斤": "{weight} kg",
    "长宽高(单位公分)": "Length, width, height (cm)",
    "体积": "Volume",
    "运输方式": "Transport type",
    "计费方式": "Billing method",
    "折扣": "Discount",
    "转运费": "Subtotal",
    "铢": "Baht",
    "共铢(不含到付收费)": "A total of {amount} baht (not including payment on delivery)",
    "收件地址": "Address",
    "登记单号，选择您需要的增值服务": "Registration tracking number, select the value-added service you need",
    "运单号": "Tracking number",
    "请输入运单号": "Please enter tracking number",
    "陆运": "Land",
    "海运": "Sea",
    "海运不满30KG或0.2立方，按30KG收费": "Sea freight of less than 30 kg or 0.2 cbm will be automatically charged at 30 kg",
    "保价": "Insurance",
    "保价金额，保费": "Insured amount:{declareAmount}，Insurance premium:{insuranceFee}",
    "未保价运单按运费三倍赔偿": "Please be noted that the uninsured orders will be compensated at three times the freight",
    "打木架": "Wooden frame",
    "到付快递费": "Payment on delivery",
    "按中国仓实付金额收费": "The actual amount paid by China warehouse will be charged accordingly",
    "中国仓退回": "Notice warehouse to reject this item",
    "备注": "Remarks",
    "请输入备注": "Please enter remarks",
    "我已阅读并同意": "I have read and agree",
    "《服务条款》": "Terms of Service",
    "请填写运单号": "Please enter tracking number",
    "单号最多输入60个字符": "Enter a maximum of 60 characters for the tracking number",
    "单号只能输入字母、数字和横线,长度不超过60": "The single number can only enter letters, numbers and horizontal lines, and the length does not exceed 60",
    "请选择运输方式": "Please select transport type",
    "请先阅读并同意服务条款": "Please read and agree to the terms of service first",
    "登记成功": "Register success",
    "count件包裹待支付": "{count} parcel to be paid",
    "首重计费": "First billing",
    "折扣立减": "Discount",
    "请选择优惠券": "Please select coupon",
    "无可用优惠券": "No coupons available",
    "实付": "Total",
    "支付": "Pay",
    "汇率": "Exchange rate",
    "剩余": "Balance",
    "取消用券": "Cancel coupon",
    "我们将尽快为您配送上门，感谢您的支持。": "We will deliver to your door as soon as possible, thank you for your support.",
    "如有问题请联系客服微信": "If you have any questions, feel free to contact our customer service via Line Official",
    "[复制]": "[Copy]",
    "前往查件": "Back to check parcels",
    "下单成功": "Successfully ordered",
    "请以英文或泰文填写收件人姓名和地址": "Please fill in the recipient's name and address in English or Thai",
    "姓名": "Name",
    "邮编": "Postcode",
    "确 定": "Confirm",
    "清 空": "Clear",
    "请输入姓名": "Please enter your name",
    "姓名最多输入30个字符": "Enter up to 30 characters for your name",
    "请输入电话": "Please enter mobile",
    "电话最少输入10个字符": "Enter at least 10 characters for the phone",
    "电话最多输入10个字符": "Enter up to 10 characters for the phone",
    "请输入详细地址": "Please enter the detailed address",
    "收货地址不能有汉字": "The delivery address cannot have Chinese characters",
    "地址最少输入12个字符": "Enter at least 12 characters for the address",
    "地址最多输入300个字符": "Enter up to 300 characters for the address",
    "请输入邮编": "Please enter postcode",
    "邮编不能有汉字": "Postcode cannot have Chinese characters",
    "邮编最少输入5个数字": "Enter at least 5 digits for the postcode",
    "邮编最多输入5个数字": "Enter up to 5 digits for the postcode",
    "新增": "Added",
    "修改": "Edit",
    "地址成功": "Added new address",
    "地址失败": "Wrong address",
    "月月发券，四季常有": "Coupons issued monthly, available in year round",
    "每月1号开始可领取月度优惠券": "Grab your coupons from the 1st of every month",
    "可用券": "Available coupons",
    "有效期至": "Valid until",
    "已过期": "Expired",
    "使用日期": "Date of usage",
    "已用券": "Used coupons",
    "保价金额": "Insured amount",
    "请输入人民币金额": "Please enter the THB amount",
    "保费": "Insurance",
    "按保价金额的%自动计算": "Automatically calculated at {rate}% of the insured amount",
    "取消保险": "Cancel insurance",
    "最高保价金额人民币": "Maximum insured amount is {amount} THB",
    "快递单号": "Tracking number",
    "共count件包裹": "A total of {count} packages",
    "收件信息": "Received",
    "备注最多不超过15个字符": "Remark cannot exceed 15 characters",
    "编辑成功": "Editted",
    "暂时没有找到相关运单": "No waybill found",
    "请输入要搜索的快递单号": "Please enter the tracking number",
    "包裹到达泰国后将通知您支付转运费并配送上门。": "After the parcel arrives in Bangkok, you will be notified to pay the freight.",
    "继续预报运单": "Select more additional services",
    "待支付": "To be paid",
    "已支付": "Paid",
    "等待入仓": "Waiting for entry",
    "到达中转仓": "Received at warehouse",
    "泰国仓分拣中": "Sorting",
    "您已签收": "Signed",
    "安排配送中": "Arrangement for delivery",
    "待打包": "Ready to pack",
    "已打包": "Packed",
    "问题件": "Questionable parcel",
    "按重量": "By weight",
    "按体积": "By volume",
    "请转账至": "Please transfer to",
    "银行": "Bank",
    "账号": "Account",
    "户名": "Name",
    "上传网银截图": "Upload payment slip",
    "金额": "Amount",
    "请输入充值金额": "Please enter the top up amount",
    "账户余额": "Balance",
    "充值": "Top up",
    "用户余额充值": "Top up",
    "运费返还": "Freight return",
    "运单赔付": "Waybill compensation",
    "待审核": "Waiting for confirmation",
    "审核不通过": "Confirmation failed",
    "暂无记录": "No data",
    "余额": "Balance",
    "设为默认收件地址": "Set as default",
    "编辑": "Edit",
    "删除": "Delete",
    "新增地址": "Add address",
    "请选择收货地址": "Please select the delivery address",
    "配送方式": "Delivery method",
    "请选择配送方式": "Please choose a delivery method",
    "余额不足": "Insufficient balance",
    "登录中...": "Logging in ...",
    "小蜜蜂物流": "Xiaomifeng express",
    "申请": "Apply",
    "获取你的昵称、头像": "Confirm your nickname and profile picture",
    "Line个人信息": "Line personal information",
    "允许": "Permit",
    "未保价的包裹按运费三倍赔偿（不超过物值），保价后按保价金额赔偿，贵重物品建议您预报运单并保价。易碎品或容易被压坏的包裹建议您打木架，否则无法保证货品安全。": "For the safety of the fragile items, we recommend our customers to add wooden crates to prevent damages during shipping",
    "如何消费？>>": "How to pay?",
    "本地派送预付费用": "Delivery fee paid by Tukship in China",
    "的红包": "s coupon",
    "一起来分享": "Let's share",
    "已存入您的小蜜蜂账户余额，可直接消费": "Credits topped up, you can make payment now",
    "您来迟了,红包已被抢光了!": "Sorry, you're late. Coupon is finished.",
    "count个红包已被领取": "{count} coupons have been picked",
    "乡/市/省/邮编": "Sub-district, district, province, postal code",
    "请选择省市区": "Please choose your district, city and postal code",
    "保险": "Insurance",
    "国内快递费到付": "COD in China",
    "运费计算器": "Freight calculator",
    "尺寸": "Measurement",
    "长": "Length",
    "宽": "Width",
    "高": "Height",
    "请输入重量": "Please input weight",
    "国际运费": "International freight",
    "本地运费": "Local freight",
    "运费": "Freight",
    "计算": "Calculate",
    "请输入长度": "Please enter the length",
    "请输入宽度": "Please enter the width",
    "请输入高度": "Please enter the height",
    "费用": "Total",
    "货物类别": "Types of goods",
    "重置": "Reset",
    "计算结果": "Grand total",
    "打木架费": "Wooden frame assemble",
    "A类": "Type A",
    "B类": "Type B",
    "C类": "Type C",
    "单位": "Unit",
    "查看价格表": "Check the price list",
    "选填": "Optional",
    "必填": "Necessary",
    "预估费用": "Approximate cost",
    "下拉即可刷新...": "Slide down to refresh",
    "释放即可刷新...": "Release to refresh",
    "地址管理": "Address management",
    "地址簿": "Address book",
    "运单详情": "Waybill information",
    "保险服务": "Goods insurance",
    "我的优惠券": "My coupons",

    "我的余额": "My credit",
    "小蜜蜂红包": "Tukship's cash coupons",
    "默认": "Set as primary",
    "选为收件地址": "Selected as recipient address",
    "没有": "None",
    "恭喜获得amount泰铢红包": "Congrats! You received {amount} THB cash coupon",
    "分享给小伙伴，大家一起抢": "",
    "分享到Line领取": "Share to LINE to receive",
    "下次再说": "Skip this time",
    "自提": "Self pick-up",
    "小蜜蜂": "Maximus",
    "JD": "JD",
    "Deliveree": "Deliveree",
    "您已经登记此包裹，可在「查件」页面搜索单号": "This parcel has been registered into the system, please go to [Order Check] to search",

    "昵称": "Name",

    "商品详情": "Product details",
    "下单": "Order",
    "收银台": "Checkout",
    "登录": "Registration",
    "订单": "Orders",
    "HOME": "HOME",
    "CART": "CART",
    "PROFILE": "PROFILE",
    "Remove": "Remove",
    "Submit": "Submit",
    "Total": "Total",
    "确定从购物车移除吗？": "Confirm product transfer?",
    "商品已从购物车移除": "Product transferred",
    "My Orders": "My Orders",
    "All orders": "All orders",
    "To Pay": "To Pay",
    "To be confirmed": "To be confirmed",
    "To Ship": "To Ship",
    "To Receive": "To Receive",
    "Return & Cancellations": "Return & Cancellations",
    "Addresses": "Addresses",
    "Link with  Line / Facebook": "Link with  Line / Facebook",
    "修改地址成功": "Address changed",
    "新增地址成功": "Address added",
    "Add Address": "Add Address",
    "确定删除地址吗？": "Confirm address deletion?",
    "地址已删除": "Address deleted",
    "No addresses": "No addresses",
    "All": "All",
    "Total Shipping Price": "Total Shipping Price",
    "Total Amount": "Total Amount",
    "Add new address": "Add new address",
    "Order Details": "Order Details",
    "Pay Now": "Pay Now",
    "BUY NOW": "BUY NOW",
    "ADD TO CART": "ADD TO CART",
    "库存": "Inventory",
    "Quantity": "Quantity",
    "stock insufficient": "stock insufficient",
    "add cart success": "add cart success",
    "输入关键字搜索": "Please enter keyword to search",
    "Recent Search": "Recent Search",
    "sold": "sold",
    "取消等待": "Cancel",
    "Sync failed": "Sync failed",
    "价格": "Price",
    "起批量": "MOQ",
    "amount件起批": "Minimum order {amount} pcs",
    "件_": "pc",
    "请先选择收件地址": "Please choose recepient address",
    "优惠": "Discount",
    "Cart is empty": "Cart is empty",
    "前往登录": "Log in",
    "电话号码必须以0开头10位数": "Mobile number should start with 0 and has 10 digits",
    "请输入手机号": "Please enter mobile number",
    "请输入验证码": "Please enter OTP",
    "获取验证码": "Get OTP code",
    "counts后重新发送": "Resend in {count} s",
    "商品不存在": "No existing product",
    "短信验证码不正确或已失效": "Wrong OTP / Failed",
    "Cancel Order": "Cancel Order",
    "Subtotal": "Subtotal",
    "Freight": "Freight",
    "Calculating": "Calculating",
    "Are you sure you want to cancel order": "Are you sure you want to cancel order {orderName}?",
    "overseas": "overseas",
    "Pay for all orders": "Pay for all orders",
    "by land transpotation": "by land transpotation",
    "by sea transpotation": "by sea transpotation",
    "This is an estimate and actual amounts may vary.": "This is an estimate and actual amounts may vary.",
    "orders amount": "{amount} orders",
    "Pay": "Pay",
    "Tracking": "Tracking",
    "You have amount cross-border orders that will be shipped when the freight is paid. Please select transportation type to calculate the freight.": "You have {amount} cross-border orders that will be shipped when the freight is paid. Please select transportation type to calculate the freight.",
    "See Rates >>": "See Rates >>",
    "Select all amount cross-border orders": "Select all {amount} cross-border orders",
    "Weight": "Weight",
    "Volume": "Volume",
    "Waiting for the goods to arrive at the warehouse": "Waiting for the goods to arrive at the warehouse",
    "Total Weight": "Total Weight",
    "Total Volume": "Total Volume",
    "Total Freight": "Total Freight",
    "Pay Freight": "Pay Freight",
    "Please select transportation type": "Please select transportation type",
    "This order has been delivered to our warehouse. The logistic fee for send it back to the supplier will be deducted from the refund. Are you sure you want to cancel?": "This order has been delivered to our warehouse. The logistic fee for send it back to the supplier will be deducted from the refund. Are you sure you want to cancel?",
    "Transportation type": "Transportation type",
    "Please Select": "Please Select",
    "Slip uploaded successfully": "Slip uploaded successfully",
    "If you have any questions, feel free to contact our customer service via Line Offical @beericher": "If you have any questions, feel free to contact our customer service via Line Offical @beericher",
    "Home Page": "Home Page",
    "Order No": "Order No",
    "This is a cross-border order": "This is a cross-border order",
    "Cancel": "Cancel",
    "Refund policy>>": "Refund policy>>",
    "订单取消成功": "订单取消成功",
    "Please transfer and upload slip": "Please transfer and upload slip",
    "Bank slip": "Bank slip",
    "Bank": "Bank",
    "Account No.": "Account No.",
    "Account Name": "Account Name",
    "Amount": "Amount",
    "See sample slip": "See sample slip",
    "ratings": "{ratings} ratings",
    "Shipping": "Shipping",
    "The supplier ships the goods to our warehouse in China": "The supplier ships the goods to our warehouse in China",
    "You pay the cross border freight before a cross border logistic company ships the goods to Thailand.": "You pay the cross border freight before a cross border logistic company ships the goods to Thailand.",
    "Click to Estimate freight": "Click to Estimate freight",
    "A local express company delivers the goods to your address": "A local express company delivers the goods to your address",
    "See Shipping Details >>": "See Shipping Details >>",
    "Cancelled": "Cancelled",
    "Clck to Estimate freight and Transit Time": "Clck to Estimate freight and Transit Time",
    "Minimum order quantity": "Minimum order quantity",
    "Refundable": "Refundable",
    "Refund": "Refund",
    "Refunded": "Refunded",
    "Refunding": "Refunding",
    "Additional Cross-border Freight": "Additional Cross-border Freight",
    "amount cross-border orders will be shipped when the freight is paid": "{amount} cross-border orders will be shipped when the freight is paid",
    "Deliver to door": "Deliver to door",
    "Cross-border": "Cross-border",
    "notice to pay": "Notice: For shipments from foreign countries, you will need to pay additional Cross-border shipping  charges. We will notify you when the carrier has received the shipment and has calculated the amount based on the weight or volume of the shipment.",
    "See details": "See details",
    "全选": "Select all",
    "orders shipped together": "{amount} orders shipped together",
    "Arrived at the transite warehouse": "Arrived at the transite warehouse",
    "Cross border Freight Paid": "Cross border Freight Paid",
    "Ready to ship to Thailand": "Ready to ship to Thailand",
    "Fulfilled by the supplier": "Fulfilled by the supplier",
    "Order placed": "Order placed",
    "orders have been shipped together": "{amount} orders have been shipped together",
    "Waybills": "Waybills",
    "Total packages": "Total {amount} packages",
    "Orders": "Orders",
    "Completed": "Completed",
    "Ordered successfully": "Ordered successfully",
    "Order cancelled successfully": "Order cancelled successfully",
    "You will receive your refund upon receipt of the returned goods by the supplier. The shipping cost to send the goods back to the supplier will be deducted from your refund.  If you have any questions, please contact us via Line Offical @beericher": "You will receive your refund upon receipt of the returned goods by the supplier. The shipping cost to send the goods back to the supplier will be deducted from your refund.  If you have any questions, please contact us via Line Offical @beericher",
    "You will receive your refund within 3 business days. If you have any questions, please contact us via Line Offical @beericher": "You will receive your refund within 3 business days. If you have any questions, please contact us via Line Offical @beericher",
    "Order failed": "Order failed",
    "Please login": "Please login",
    "You have successfully logged in": "You have successfully logged in",
    "Help center": "Help center",
    "What is BeeRicher": "What is BeeRicher",
    "How to order?": "How to order?",
    "How to pay?": "How to pay?",
    "How to handle short shipment and wrong shipment?": "How to handle short shipment and wrong shipment?",
    "How to contact the cross-border logistic service provider?": "How to contact the cross-border logistic service provider?",
    "How to make a claim for damaged or lost shipment?": "How to make a claim for damaged or lost shipment?",
    "Cancel, Refund & Return Policy": "Cancel, Refund & Return Policy",
    "Five differences between BeeRicher and other sourcing agents": "Five differences between BeeRicher and other sourcing agents",
    "hc-Shipping": "Shipping",
    "未登录": "Not logged in",
    "SEARCH HISTORY": "SEARCH HISTORY",
    "正在查找商品，请等待一会": "Searching for products, please wait",
    "正在更新数据...": "data updating...",
    "返回首页": "Home Page",
    "该商品已下架": "This product is no longer available",
    "缺货": "Stockout",
    "退出登录": "Sign out",
    "到达泰国仓": "arrived In Bangkok",
    "已取消": "Cancelled",
    "已揽收": "Picked Up",
    "已退回泰国仓": "Returned",
    "出库中": "Leaving warehouse",
    "您正在寻找的商品": "The product you are looking for"
}