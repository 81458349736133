import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/home',
    name: 'Home',
    component: () => import('../views/home/Index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/ads',
    name: 'Ads',
    component: () => import('../views/ads/Index.vue'),
  },
  {
    path: '/addresses',
    name: 'addresses',
    component: () => import('../views/address/Index.vue'),
    meta: {
      title: '地址管理'
    }
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import('../views/product/Product.vue'),
    meta: {
      title: '商品详情'
    }
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('../views/address/Address.vue'),
    meta: {
      title: '地址簿'
    }
  },
  {
    path: '/placeOrder',
    name: 'placeOrder',
    component: () => import('../views/placeorder/Index.vue'),
    meta: {
      title: '下单'
    }
  },
  {
    path: '/placeOrder/cashier',
    name: 'cashier',
    component: () => import('../views/placeorder/ScreenShot.vue'),
    meta: {
      title: '收银台'
    }
  },
  {
    path: '/success',
    name: 'success',
    component: () => import('../views/success/Index.vue'),
    meta: {
      title: '下单成功'
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/order/Index.vue'),
    meta: {
      title: '订单'
    }
  },
  {
    path: '/',
    name: 'login',
    component: () => import('../views/login/Index.vue'),
    meta: {
      title: '登录'
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/search/Index.vue'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/shopee/search',
    name: 'shopeeSearch',
    component: () => import('../views/shopee/Index.vue'),
  },
  {
    path: '/aliproduct',
    name: 'aliproduct',
    component: () => import('../views/ali88/Index.vue'),
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/pay/Index.vue'),
  },
  {
    path: '/order/detail',
    name: 'orderDetail',
    component: () => import('../views/order/detail/Index.vue'),
  },
  {
    path: '/order/shipping',
    name: 'shippingDetail',
    component: () => import('../views/shipping/Index.vue'),
  },
  {
    path: '/product/takeoff',
    name: 'takeoff',
    component: () => import('../views/take-off/Index.vue'),
  },
  {
    path: '/order/tracking',
    name: 'tracking',
    component: () => import('../views/shipping/Tracking.vue'),
  },
  {
    path: '/cargo/detail',
    name: 'cargoDetail',
    component: () => import('../views/cargo/Detail.vue'),
    meta: {
      title: '运单详情'
    }
  },
  {
    path: '/calculator',
    name: 'calculator',
    component: () => import('../views/calculator/Index.vue'),
    meta: {
      keepAlive: true,
      title: '运费计算器'
    }
  },
  {
    path: '/help',
    name: 'helpcenter',
    component: () => import('../views/helpcenter/Index.vue'),
    meta: {
      keepAlive: true,
      title: 'Help center'
    }
  },
  {
    path: '/testcss',
    name: 'testcss',
    component: () => import('../views/test/TestCss.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router