import Vue from 'vue'
import App from './App.vue'
import router from './router'
import {
  i18n
} from '@/utils/lang'
import VConsole from 'vconsole'
import store from './store'
import axios from 'axios'

Vue.prototype.$axios = axios
Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'production') {
  new VConsole()
}

function NumberMul(arg1, arg2) {
  var m = 0;
  var s1 = arg1.toString();
  var s2 = arg2.toString();
  try {
    m += s1.split('.')[1].length;
  } catch (e) {
    //
  }
  try {
    m += s2.split('.')[1].length;
  } catch (e) {
    //
  }
  return Number(s1.replace('.', '')) * Number(s2.replace('.', '')) / Math.pow(10, m)
}

function NumberAdd(arg1, arg2) {
  var r1, r2, m, n
  try {
    r1 = arg1.toString().split('.')[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split('.')[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2))
  n = (r1 >= r2) ? r1 : r2
  return ((arg1 * m + arg2 * m) / m).toFixed(n)
}

function NumberMinus(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length
  } catch (e) {
    r1 = 0
  }
  try {
    r2 = arg2.toString().split(".")[1].length
  } catch (e) {
    r2 = 0
  }
  m = Math.pow(10, Math.max(r1, r2));
  //last modify by deeka
  //动态控制精度长度
  n = (r1 >= r2) ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

Vue.prototype.NumberMul = NumberMul
Vue.prototype.NumberMinus = NumberMinus
Vue.prototype.NumberAdd = NumberAdd

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')


router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title == undefined ? 'Beericher' : i18n.t(to.meta.title)
  next()
})