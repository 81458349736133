export default {
    "没有更多了": "沒有更多了",
    "加载中...": "加載中...",
    "复制成功": "複製成功",
    "确认": "確認",
    "确定": "確定",
    "取消": "取消",
    "暂无数据": "暫無數據",
    "查件": "查件",
    "客服": "客服",
    "我的": "我的",
    "搜索快递单号": "搜索快遞單號",
    "搜索": "搜索",
    "发往泰国(total)": "發往泰國({total})",
    "发往泰国": "發往泰國",
    "点击支付 >>": "您有{total}個包裹到達泰國倉,支付後派送上門。點擊支付 >>",
    "暂无发往泰国运单": "暫無發往泰國運單",
    "派送上门(total)": "派送上門({total})",
    "派送上门": "派送上門",
    "暂无派送上门运单": "暫無派送上門運單",
    "已签收": "已簽收",
    "件": "件",
    "暂无已签收运单": "暫無已簽收運單",
    "小蜜蜂转运优惠券": "小蜜蜂轉運優惠券",
    "券": "券",
    "折": "折",
    "无门槛": "無門檻",
    "满可用": "滿{amount}銖可用",
    "领取后请到[我的-优惠劵]查看": "領取後請到[我的-優惠劵]查看",
    "填写收件地址后可以领取优惠券": "填寫收件地址後可以領取優惠券",
    "一键领取": "一鍵領取",
    "前往填写收件地址": "前往填寫收件地址",
    "您已成功领取": "您已成功領取",
    "领取失败,稍后再试": "領取失敗,稍後再試",
    "客服微信": "客服微信",
    "[点击复制]": "[點擊複製]",
    "语言切换": "語言切換",
    "使用说明": "使用說明",
    "时效与价格": "時效與價格",
    "热门问题": "熱門問題",
    "什么不能寄？": "什麼不能寄？",
    "证件例如身份证毕业证，烟，酒，易燃易爆物品，危险化学品，生鲜品，植物及种子，动物等违反中泰两国法律的物品。": "證件例如身份證畢業證，煙，酒，易燃易爆物品，危險化學品，生鮮品，植物及種子，動物等違反中泰兩國法律的物品。",
    "丢件或损毁如何赔付？": "丟件或損毀如何賠付？",
    "未保价的包裹按运费三倍赔偿，保价后按保价金额赔偿，贵重物品请务必预报运单并保价。": "未保價的包裹按運費三倍賠償，保價後按保價金額賠償，貴重物品請務必預報運單並保價。",
    "如何付运费？": "如何付運費？",
    "包裹到达泰国后，系统将给您发送运费通知，支付后系统安排配送包裹上门。": "包裹到達泰國後，系統將給您發送運費通知，支付後系統安排配送包裹上門。",
    "是否有最低重量要求？": "是否有最低重量要求？",
    "单批包裹不满一公斤按一公斤计费，例如您有两个包裹到达泰国仓库，如果两个包裹的总重量不足一公斤, 按一公斤计费。如果您还有包裹在路上，先到的包裹可以暂存泰国仓，等到齐之后一起计费付款，再派送上门。": "單批包裹不滿一公斤按一公斤計費，例如您有兩個包裹到達泰國倉庫，如果兩個包裹的總重量不足一公斤, 按一公斤計費。如果您還有包裹在路上，先到的包裹可以暫存泰國倉，等到齊之後一起計費付款，再派送上門。",
    "用户代码": "用戶代碼",
    "泰国收件地址": "泰國收件地址",
    "点击填写收件地址": "點擊填寫收件地址",
    "预报运单": "預報運單",
    "退回/保价/打木架/到付": "退回/保價/打木架/到付",
    "优惠券": "優惠券",
    "转运仓信息": "轉運倉信息",
    "收件人": "收件人",
    "电话": "電話",
    "地址": "地址",
    "[复制转运仓信息]": "[複製轉運倉信息]",
    "张": "張",
    "无可用券": "無可用券",
    "请您先填写泰国收件地址": "請您先填寫泰國收件地址",
    "单号": "單號",
    "[编辑备注]": "[編輯備註]",
    "[复制单号]": "[複製單號]",
    "增值服务": "增值服務",
    "共铢(不含到付)": "共{amount}銖(不含到付)",
    "等待包裹入库": "等待包裹入庫",
    "重量": "重量",
    "weight 公斤": "{weight} 公斤",
    "长宽高(单位公分)": "長寬高(單位公分)",
    "体积": "體積",
    "运输方式": "運輸方式",
    "计费方式": "計費方式",
    "折扣": "折扣",
    "转运费": "轉運費",
    "铢": "銖",
    "共铢(不含到付收费)": "共{amount}銖(不含到付收費)",
    "收件地址": "收件地址",
    "登记单号，选择您需要的增值服务": "登記單號，選擇您需要的增值服務",
    "运单号": "運單號",
    "请输入运单号": "請輸入運單號",
    "陆运": "陸運",
    "海运": "海運",
    "海运不满30KG或0.2立方，按30KG收费": "海運不滿30KG或0.2立方，按30KG收費",
    "保价": "保價",
    "保价金额，保费": "保價金額:{declareAmount}，保費:{insuranceFee}",
    "未保价运单按运费三倍赔偿": "未保價運單按運費三倍賠償",
    "打木架": "打木架",
    "到付快递费": "到付快遞費",
    "按中国仓实付金额收费": "按中國倉實付金額收費",
    "中国仓退回": "中國倉退回",
    "备注": "備註",
    "请输入备注": "請輸入備註",
    "我已阅读并同意": "我已閱讀並同意",
    "《服务条款》": "《服務條款》",
    "请填写运单号": "請填寫運單號",
    "单号最多输入60个字符": "單號最多輸入60個字符",
    "单号只能输入字母、数字和横线,长度不超过60": "單號只能輸入字母、數字和橫線,長度不超過60",
    "请选择运输方式": "請選擇運輸方式",
    "请先阅读并同意服务条款": "請先閱讀並同意服務條款",
    "登记成功": "登記成功",
    "count件包裹待支付": "{count}件包裹待支付",
    "首重计费": "首重計費",
    "折扣立减": "折扣立減",
    "请选择优惠券": "請選擇優惠券",
    "无可用优惠券": "無可用優惠券",
    "实付": "實付",
    "支付": "支付",
    "汇率": "匯率",
    "剩余": "剩餘",
    "取消用券": "取消用券",
    "我们将尽快为您配送上门，感谢您的支持。": "我們將盡快為您配送上門，感謝您的支持。",
    "如有问题请联系客服微信": "如有問題請聯繫客服微信",
    "[复制]": "[複製]",
    "前往查件": "前往查件",
    "下单成功": "下單成功",
    "请以英文或泰文填写收件人姓名和地址": "請以英文或泰文填寫收件人姓名和地址",
    "姓名": "姓名",
    "邮编": "郵編",
    "确 定": "確 定",
    "清 空": "清 空",
    "请输入姓名": "請輸入姓名",
    "姓名最多输入30个字符": "姓名最多輸入30個字符",
    "请输入电话": "請輸入電話",
    "电话最少输入10个字符": "電話最少輸入10個字符",
    "电话最多输入10个字符": "電話最多輸入10個字符",
    "请输入详细地址": "請輸入詳細地址",
    "收货地址不能有汉字": "收貨地址不能有漢字",
    "地址最少输入12个字符": "地址最少輸入12個字符",
    "地址最多输入300个字符": "地址最多輸入300個字符",
    "请输入邮编": "請輸入郵編",
    "邮编不能有汉字": "郵編不能有漢字",
    "邮编最少输入5个数字": "郵編最少輸入5個數字",
    "邮编最多输入5个数字": "郵編最多輸入5個數字",
    "新增": "新增",
    "修改": "修改",
    "地址成功": "地址成功",
    "地址失败": " 地址失敗",
    "月月发券，四季常有": "月月發券，四季常有",
    "每月1号开始可领取月度优惠券": "每月1號開始可領取月度優惠券",
    "可用券": "可用券",
    "有效期至": "有效期至",
    "已过期": "已過期",
    "使用日期": "使用日期",
    "已用券": "已用券",
    "保价金额": "保價金額",
    "请输入人民币金额": "請輸入人民幣金額",
    "保费": "保費",
    "按保价金额的%自动计算": "按保價金額的{rate}%自動計算",
    "取消保险": "取消保險",
    "最高保价金额人民币": "最高保價金額{amount}人民幣",
    "快递单号": "快遞單號",
    "共count件包裹": "共{count}件包裹",
    "收件信息": "收件信息",
    "备注最多不超过15个字符": "備註最多不超過15個字符",
    "编辑成功": "編輯成功",
    "暂时没有找到相关运单": "暫時沒有找到相關運單",
    "请输入要搜索的快递单号": "請輸入要搜索的快遞單號",
    "包裹到达泰国后将通知您支付转运费并配送上门。": "包裹到達泰國後將通知您支付轉運費並配送上門。",
    "继续预报运单": "繼續預報運單",
    "待支付": "待支付",
    "已支付": "已支付",
    "等待入仓": "等待入倉",
    "到达中转仓": "到達中轉倉",
    "泰国仓分拣中": "泰國倉分揀中",
    "您已签收": "您已簽收",
    "安排配送中": "安排配送中",
    "待打包": "待打包",
    "已打包": "已打包",
    "问题件": "問題件",
    "按重量": "按重量",
    "按体积": "按體積",
    "请转账至": "請轉賬至",
    "银行": "銀行",
    "账号": "賬號",
    "户名": "戶名",
    "上传网银截图": "上傳網銀截圖",
    "金额": "金額",
    "请输入充值金额": "請輸入充值金額",
    "账户余额": "賬戶餘額",
    "充值": "充值",
    "用户余额充值": "用戶餘額充值",
    "运费返还": "運費返還",
    "运单赔付": "運單賠付",
    "待审核": "待審核",
    "审核不通过": "審核不通過",
    "暂无记录": "暫無記錄",
    "余额": "餘額",
    "设为默认收件地址": "設為默認收件地址",
    "编辑": "編輯",
    "删除": "刪除",
    "新增地址": "新增地址",
    "请选择收货地址": "請選擇收貨地址",
    "配送方式": "配送方式",
    "请选择配送方式": "請選擇配送方式",
    "余额不足": "餘額不足",
    "登录中...": "登錄中 ...",
    "小蜜蜂物流": "小蜜蜂物流",
    "申请": "申請",
    "获取你的昵称、头像": "獲取你的暱稱、頭像",
    "Line个人信息": "Line個人信息",
    "允许": "允許",
    "未保价的包裹按运费三倍赔偿（不超过物值），保价后按保价金额赔偿，贵重物品建议您预报运单并保价。易碎品或容易被压坏的包裹建议您打木架，否则无法保证货品安全。": "未保價的包裹按運費三倍賠償（不超過物值），保價後按保價金額賠償，貴重物品建議您預報運單並保價。易碎品或容易被壓壞的包裹建議您打木架，否則無法保證貨品安全。",
    "如何消费？>>": "如何消費?>>",
    "本地派送预付费用": "本地派送預付費用",
    "的红包": "的紅包",
    "一起来分享": "一起來分享",
    "已存入您的小蜜蜂账户余额，可直接消费": "已存入您的小蜜蜂賬戶餘額，可直接消費",
    "您来迟了,红包已被抢光了!": "您來遲了,紅包已被搶光了!",
    "count个红包已被领取": "{count}個紅包已被領取",
    "乡/市/省/邮编": "鄉/市/省/郵編",
    "请选择省市区": "請選擇省市區",
    "保险": "保險",
    "国内快递费到付": "國內快遞費到付",
    "运费计算器": "運費計算器",
    "尺寸": "尺寸",
    "长": "長",
    "宽": "寬",
    "高": "高",
    "请输入重量": "請輸入重量",
    "国际运费": "國際運費",
    "本地运费": "本地運費",
    "运费": "運費",
    "计算": "計算",
    "请输入长度": "請輸入長度",
    "请输入宽度": "請輸入寬度",
    "请输入高度": "請輸入高度",
    "费用": "費用",
    "货物类别": "貨物類別",
    "重置": "重置",
    "计算结果": "計算結果",
    "打木架费": "打木架費",
    "A类": "A類",
    "B类": "B類",
    "C类": "C類",
    "单位": "單位",
    "查看价格表": "查看價格表",
    "选填": "選填",
    "必填": "必填",
    "预估费用": "預估費用",
    "下拉即可刷新...": "下拉即可刷新...",
    "释放即可刷新...": "釋放即可刷新...",
    "地址管理": "地址管理",
    "地址簿": "地址簿",
    "运单详情": "運單詳情",
    "保险服务": "保險服務",
    "我的优惠券": "我的優惠券",
    "我的余额": "我的餘額",
    "小蜜蜂红包": "小蜜蜂紅包",
    "默认": "默認",
    "选为收件地址": "選為收件地址",

    "没有": "沒有",
    "恭喜获得amount泰铢红包": "恭喜獲得{amount}泰銖紅包",
    "分享给小伙伴，大家一起抢": "分享給小伙伴，大家一起搶",
    "分享到Line领取": "分享到Line領取",
    "下次再说": "下次再說",
    "自提": "自提",
    "小蜜蜂": "小蜜蜂",
    "JD": "JD",
    "Deliveree": "Deliveree",
    "您已经登记此包裹，可在「查件」页面搜索单号": "您已經登記此包裹，可在[查件]頁面搜索單號",

    "昵称": "昵稱",

    "商品详情": "商品詳情",
    "下单": "下單",
    "收银台": "收銀台",
    "登录": "登錄",
    "订单": "訂單",
    "HOME": "HOME",
    "CART": "CART",
    "PROFILE": "PROFILE",
    "Remove": "刪除",
    "Submit": "提交",
    "Total": "共計",
    "确定从购物车移除吗？": "確定從購物車移除嗎？",
    "商品已从购物车移除": "商品已從購物車移除",
    "My Orders": "我的訂單",
    "All orders": "所有訂單",
    "To Pay": "待支付",
    "To be confirmed": "待確認",
    "To Ship": "待發貨",
    "To Receive": "待收貨",
    "Return & Cancellations": "退貨和取消",
    "Addresses": "地址",
    "Link with  Line / Facebook": "綁定Line/Facebook",
    "修改地址成功": "修改地址成功",
    "新增地址成功": "新增地址成功",
    "Add Address": "新增地址",
    "确定删除地址吗？": "確定刪除地址嗎？",
    "地址已删除": "地址已刪除",
    "No addresses": "沒有地址",
    "All": "全部",
    "Total Shipping Price": "總運費",
    "Total Amount": "總金額",
    "Add new address": "新增地址",
    "Order Details": "訂單詳細信息",
    "Pay Now": "現在付款",
    "BUY NOW": "立即購買",
    "ADD TO CART": "添加到購物車",
    "库存": "庫存",
    "Quantity": "數量",
    "stock insufficient": "庫存不足",
    "add cart success": "添加購物車成功",
    "输入关键字搜索": "輸入關鍵字搜索",
    "Recent Search": "最近搜索",
    "sold": "已售",
    "取消等待": "取消等待",
    "Sync failed": "同步失敗",
    "价格": "價格",
    "起批量": "起批量",
    "amount件起批": "{amount}件起批",
    "件_": "件",
    "请先选择收件地址": "請先選擇收件地址",
    "优惠": "優惠",
    "Cart is empty": "Cart is empty",
    "前往登录": "前往登錄",
    "电话号码必须以0开头10位数": "電話號碼必須以0開頭10位數",
    "请输入手机号": "請輸入手機號",
    "请输入验证码": "請輸入驗證碼",
    "获取验证码": "獲取驗證碼",
    "counts后重新发送": "{count}s後重新發送",
    "商品不存在": "商品不存在",
    "短信验证码不正确或已失效": "短信驗證碼不正確或已失效",
    "Cancel Order": "取消訂單",
    "Subtotal": "小計",
    "Freight": "運費",
    "Calculating": "計算中",
    "Are you sure you want to cancel order": "確定取消訂單{orderName}?",
    "overseas": "海外",
    "Pay for all orders": "支付所有訂單",
    "by land transpotation": "陸運",
    "by sea transpotation": "海運",
    "This is an estimate and actual amounts may vary.": "實際費用可能不同.",
    "orders amount": "{amount}個訂單",
    "Pay": "支付",
    "Tracking": "軌跡",
    "You have amount cross-border orders that will be shipped when the freight is paid. Please select transportation type to calculate the freight.": "您有 {amount} 個跨境訂單將在支付運費後發貨。 請選擇運輸類型計算運費。",
    "See Rates >>": "查看價格>>",
    "Select all amount cross-border orders": "選擇所有{amount}跨境訂單",
    "Weight": "重量",
    "Volume": "體積",
    "Waiting for the goods to arrive at the warehouse": "等待所有商品到達倉庫",
    "Total Weight": "總重量",
    "Total Volume": "總體積",
    "Total Freight": "總運費",
    "Pay Freight": "支付運費",
    "Please select transportation type": "請選擇運輸方式",
    "This order has been delivered to our warehouse. The logistic fee for send it back to the supplier will be deducted from the refund. Are you sure you want to cancel?": "此訂單已送達倉庫。 退回供應商的物流費用將從退款中扣除。 你確定要取消？",
    "Transportation type": "運輸方式",
    "Please Select": "請選擇",
    "Slip uploaded successfully": "單據上傳成功",
    "If you have any questions, feel free to contact our customer service via Line Offical @beericher": "如果您有任何問題，請隨時通過 Line Official @beericher 聯繫我們的客服",
    "Home Page": "首頁",
    "Order No": "訂單號",
    "This is a cross-border order": "這是一個跨境訂單",
    "Cancel": "取消",
    "Refund policy>>": "退款策略>>",
    "订单取消成功": "訂單取消成功",
    "Please transfer and upload slip": "請轉賬並上傳憑證",
    "Bank slip": "銀行憑證",
    "Bank": "銀行",
    "Account No.": "賬號",
    "Account Name": "賬戶名",
    "Amount": "金額",
    "See sample slip": "查看憑證示例",
    "ratings": "評分{ratings}",
    "Shipping": "運輸",
    "The supplier ships the goods to our warehouse in China": "供應商將貨物運送到我們在中國的倉庫",
    "You pay the cross border freight before a cross border logistic company ships the goods to Thailand.": "您支付跨境運費，跨境物流公司將貨物運送到泰國。",
    "Click to Estimate freight": "點擊預估運費",
    "A local express company delivers the goods to your address": "當地快遞公司將貨物運送到您的地址",
    "See Shipping Details >>": "查看運輸詳情 >>",
    "Cancelled": "已取消",
    "Clck to Estimate freight and Transit Time": "單擊以估算運費和運輸時間",
    "Minimum order quantity": "最小起批量",
    "Refundable": "可退款",
    "Refund": "退款狀態",
    "Refunded": "已退款",
    "Refunding": "退款處理中",
    "Additional Cross-border Freight": "額外的跨境貨運",
    "amount cross-border orders will be shipped when the freight is paid": "{amount} 跨境訂單支付運費後發貨",
    "Deliver to door": "送貨上門",
    "Cross-border": "跨境",
    "notice to pay": "注意：對於來自國外的貨物，您需要支付額外的跨境運輸費用。 承運商收到貨物並根據重量或體積計算好运费后，我們會发消息通知您。",
    "See details": "閱讀詳情",
    "全选": "全选",
    "orders shipped together": "{amount}個訂單一起發貨",
    "Arrived at the transite warehouse": "到達中轉倉庫",
    "Cross border Freight Paid": "跨境運費已付",
    "Ready to ship to Thailand": "準備運送到泰國",
    "Fulfilled by the supplier": "供應商已發貨",
    "Order placed": "已下訂單",
    "orders have been shipped together": "{amount}個訂單已一起發貨",
    "Waybills": "運單",
    "Total packages": "總計 {數量} 個包裹",
    "Orders": "訂單",
    "Completed": "已完成",
    "Ordered successfully": "下單成功",
    "Order cancelled successfully": "訂單取消成功",
    "You will receive your refund upon receipt of the returned goods by the supplier. The shipping cost to send the goods back to the supplier will be deducted from your refund.  If you have any questions, please contact us via Line Offical @beericher": "供應商收到退貨後处理退款。 將貨物送回供應商的運費將從您的退款中扣除。 如果您有任何問題，請通過 Line Offical @beericher 聯繫我們",
    "You will receive your refund within 3 business days. If you have any questions, please contact us via Line Offical @beericher": "您將在 3 個工作日內收到退款。 如果您有任何問題，請通過 Line Offical @beericher 聯繫我們",
    "Order failed": "下单失败",
    "Please login": "请登录",
    "You have successfully logged in": "登录成功",
    "Help center": "幫助中心",
    "What is BeeRicher": "BeeRicher 是做什麼的",
    "How to order?": "如何下訂單?",
    "How to pay?": "如何付款?",
    "How to handle short shipment and wrong shipment?": "如何處理少發貨和發錯貨的問題？",
    "How to contact the cross-border logistic service provider?": "如何联系物流客服?",
    "How to make a claim for damaged or lost shipment?": "如何對損壞或丟失的貨物提出索賠？",
    "Cancel, Refund & Return Policy": "取消、退款和退貨政策",
    "Five differences between BeeRicher and other sourcing agents": "BeeRicher 與其他採購代理的五點不同",
    "hc-Shipping": "運輸",
    "未登录": "未登錄",
    "SEARCH HISTORY": "搜索記錄",
    "正在查找商品，请等待一会": "正在查找商品，请等待一会",
    "正在更新数据...": "正在更新數據...",
    "返回首页": "返回首頁",
    "该商品已下架": "該商品已下架",
    "缺货": "缺貨",
    "退出登录": "退出登錄",
    "到达泰国仓": "到達泰國倉",
    "已取消": "已取消",
    "已揽收": "已攬收",
    "已退回泰国仓": "已退回泰國倉",
    "出库中": "出庫中",
    "您正在寻找的商品": "您正在尋找的商品"
}