var storage = {
    set(key, value) {
        localStorage.setItem(key, value);
    },
    get(key) {
        return localStorage.getItem(key);
    },
    remove(key) {
        localStorage.removeItem(key)
    },
    setObj(key, value) {
        const v = JSON.stringify(value)
        localStorage.setItem(key, v);
    }
}
export default storage

import {
    STORAGE_KEYS
} from './constants'

export function setChatWoot(chatWoot) {
    storage.setObj(STORAGE_KEYS.chatWoot, chatWoot)
}

export function getChatWoot() {
    const data = JSON.parse(storage.get(STORAGE_KEYS.chatWoot))
    return data
}

export function setAuthPage(authPage) {
    storage.setObj(STORAGE_KEYS.authPage, authPage)
}

export function getAuthPage() {
    const data = storage.get(STORAGE_KEYS.authPage)
    try {
        return data ? JSON.parse(data) : data
    } catch (error) {
        return null
    }

}

export function setLocale(locale) {
    storage.setObj(STORAGE_KEYS.locale, locale)
}

export function getLocale() {
    const data = storage.get(STORAGE_KEYS.locale) || "th-TH"
    return data
}