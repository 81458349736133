import Vue from "vue";
import VueI18n from "vue-i18n";
import { Locale } from 'vant'
import storage from "@/utils/storage";

import enUS from '@/locales/en-US'
import thTH from '@/locales/th-TH'
import zhTW from '@/locales/zh-TW'

Vue.use(VueI18n);

var en = enUS, th = thTH, zh = zhTW
//外部external 翻译文件
if (window.locales) {
    const locales = window.locales;
    en = { ...en, ...locales.en }
    th = { ...th, ...locales.th }
    zh = { ...zh, ...locales.zh }
}

const messages = {
    'en-US': en, 'th-TH': th, 'zh-TW': zh
}

console.log('messages', messages);

const i18n = new VueI18n({
    locale: storage.get('locale') || 'th-TH',  // 设置默认语言
    messages, // 设置资源文件对象
    silentTranslationWarn: true,
})

const setLocales = (lang) => {
    if (lang === 'en-US') {
        Locale.use(lang, enUS)
    } else if (lang === 'zh-TW') {
        Locale.use(lang, zhTW)
    } else {
        Locale.use(lang, thTH)
    }

    storage.set('locale', lang)
    window.location.reload()
}

export { i18n, setLocales }