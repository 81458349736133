import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)
// Create a new store instance.
const store = new Vuex.Store({
    state() {
        return {
            orderProducts: [],
            selectAddress: null,
            orderId: null,
            cartCheckedVendor: null,
            checkedSellers: [],
            checkedVariants: {},
            homeIndex: 0,
            orderIndex: -1,
            isTokenExpired: false
        }
    },
    mutations: {
        setCheckedSellers(state, sellers) {
            state.checkedSellers = sellers
        },
        setOrderProducts(state, products) {
            state.orderProducts = products
        },
        setOrderAddress(state, address) {
            state.selectAddress = address
        },
        setOrderId(state, orderId) {
            state.orderId = orderId
        },
        setCartCheckedVendor(state, vendor) {
            state.cartCheckedVendor = vendor
        },
        onVariantCheck(state, [variantId, checked]) {
            state.checkedVariants[variantId] = checked
        },
        setHomeIndex(state, tabIndex) {
            state.homeIndex = tabIndex
        },
        setOrderIndex(state, orderIndex) {
            state.orderIndex = orderIndex
        },
        setTokenExpired(state, expired) {
            state.isTokenExpired = expired
        },
    }
})

export default store