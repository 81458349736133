export default {
    "没有更多了": "สิ้นสุดรายการ",
    "加载中...": "กำลังโหลด...",
    "复制成功": "คัดลอกเรียบร้อย",
    "确认": "ยืนยัน",
    "确定": "ยืนยัน",
    "取消": "ยกเลิก",
    "暂无数据": "ไม่มีข้อมูล",
    "查件": "ตรวจสอบ",
    "客服": "บริการลูกค้า",
    "我的": "ของฉัน",
    "搜索快递单号": "ค้นหาหมายเลขใบนำส่ง",
    "搜索": "ค้นหา",
    "发往泰国(total)": "จัดส่งไปประเทศไทยจำนวน ({total}) ชิ้น",
    "发往泰国": "อยู่ระหว่างจัดส่ง",
    "点击支付 >>": "พัสดุ {total} ชิ้นพร้อมจัดส่ง กดตรงนี้เพื่อชำระ >>",
    "暂无发往泰国运单": "ยังไม่มีใบนำส่งสินค้า",
    "派送上门(total)": "ส่งถึงบ้านจำนวน ({total}) ชิ้น",
    "派送上门": "กำลังจัดส่ง",
    "暂无派送上门运单": "ไม่มีใบนำส่งสินค้า",
    "已签收": "เซ็นรับเรียบร้อย",
    "件": "ชิ้น",
    "暂无已签收运单": "ไม่มีใบนำส่งสินค้าที่เซ็นรับแล้ว",
    "小蜜蜂转运优惠券": "คูปอง Tukship",
    "券": "คูปอง",
    "折": "ส่วนลด",
    "无门槛": "ไม่มีเงื่อนไข",
    "满可用": "สามารถใช้ได้จำนวน {amount} บาท",
    "领取后请到[我的-优惠劵]查看": "โปรดไปที่ [My-Discount Voucher] เพื่อค้นหาคูปอง",
    "填写收件地址后可以领取优惠券": "คุณจะได้รับคูปองส่วนลดหลังจากกรอกที่อยู่จัดส่ง",
    "一键领取": "กดรับทันที",
    "前往填写收件地址": "ไปกรอกที่อยู่จัดส่ง",
    "您已成功领取": "ได้รับคูปองแล้ว",
    "领取失败,稍后再试": "กดรับคูปองล้มเหลว โปรดลองอีกครั้ง",
    "客服微信": "บริการลูกค้าบน WeChat",
    "[点击复制]": "[คัดลอก]",
    "语言切换": "ภาษา",
    "使用说明": "วิธีใช้งาน",
    "时效与价格": "ระยะเวลาจัดส่งและอัตราค่าขนส่ง",
    "热门问题": "คำถามที่พบบ่อย",
    "什么不能寄？": "สินค้าอะไรที่ไม่สามารถส่งได้?",
    "证件例如身份证毕业证，烟，酒，易燃易爆物品，危险化学品，生鲜品，植物及种子，动物等违反中泰两国法律的物品。": "บัตรประจำตัวประชาชน ใบขับขี่ หรือเอกสารทางการต่างๆ บุหรี่ แอลกอฮอล์ วัตถุไวไฟ วัตถุระเบิด สารเคมีอันตราย ผลิตภัณฑ์อาหารสด พืชและเมล็ดพืช สิ่งมีชีวิตและสิ่งของอื่นๆที่ผิดกฎหมายของประเทศจีนและไทย",
    "丢件或损毁如何赔付？": "จะชดใช้ความเสียหายอย่างไร?",
    "未保价的包裹按运费三倍赔偿，保价后按保价金额赔偿，贵重物品请务必预报运单并保价。": "ทางบริษัทมีนโยบายชดใช้ค่าเสียหายกับพัสดุที่ไม่มีประกันเป็นจำนวนเงินสามเท่าของค่าขนส่ง และจะชดใช้ตามจำนวนเงินที่ซื้อประกันภัยเอาไว้ สำหรับสิ่งของมีค่า ทางบริษัทแนะนำทางลูกค้าให้ประเมินและคำนวนค่าเสียหายที่จะได้รับก่อนซื้อประกันสินค้าแต่ละชิ้น",
    "如何付运费？": "ชำระค่าขนส่งอย่างไร?",
    "包裹到达泰国后，系统将给您发送运费通知，支付后系统安排配送包裹上门。": "หลังจากที่พัสดุมาถึงประเทศไทย ทางระบบจะส่งข้อความแจ้งยอดค่าขนส่ง และทางโกดังก็จะจัดส่งพัสดุทั้งหมดไปยังที่อยู่จัดส่งหลังจากได้รับการชำระเงินเป็นที่เรียบร้อยแล้ว",
    "是否有最低重量要求？": "มีน้ำหนักขั้นต่ำในการจัดส่งไหม?",
    "单批包裹不满一公斤按一公斤计费，例如您有两个包裹到达泰国仓库，如果两个包裹的总重量不足一公斤, 按一公斤计费。如果您还有包裹在路上，先到的包裹可以暂存泰国仓，等到齐之后一起计费付款，再派送上门。": "พัสดุในรอบเดียวกันที่รวมขึ้นมาต่ำกว่า 1 กก.จะถูกเรียกเก็บค่าขนส่งรวมเป็นเรทของ 1 กก. ยกตัวอย่างเช่น พัสดุสองชิ้นถูกจัดส่งมาถึงโกดังสินค้าในไทยแล้วน้ำหนักรวมของทั้งสองชิ้นน้อยกว่า 1 กก. ทางบริษัทจะเรียกเก็บค่าขนส่งปัดขึ้นเป็น 1 กก. แต่หากมีพัสดุที่ถูกทยอยขนส่งมายังประเทศไทยและยังอยู่ระหว่างทาง ลูกค้าสามารถเก็บพัสดุที่ถึงแล้วไว้ชั่วคราวในโกดังสินค้าในประเทศไทยก่อน เพื่อที่จะรวมพัสดุทั้งหมดขึ้นมาถึงจนครบและรวบรวมขึ้นมาเพื่อคำนวนค่าขนส่งทีเดียว",
    "用户代码": "รหัสผู้ใช้",
    "泰国收件地址": "ข้อมูลส่วนตัวและที่อยู่จัดส่ง",
    "点击填写收件地址": "คลิ๊กเพื่อกรอกที่อยู่จัดส่ง",
    "预报运单": "จัดการพัสดุ",
    "退回/保价/打木架/到付": "คืนสินค้า/ประกันสินค้า/ตีลังไม้/ชำระเงินปลายทาง",
    "优惠券": "คูปอง",
    "转运仓信息": "ข้อมูลของโกดังจีน",
    "收件人": "ผู้รับ",
    "电话": "โทรศัพท์มือถือ",
    "地址": "ที่อยู่",
    "[复制转运仓信息]": "[คัดลอกข้อมูลของโกดังจีน]",
    "张": "ชิ้น",
    "无可用券": "ไม่มีคูปอง",
    "请您先填写泰国收件地址": "โปรดกรอกที่อยู่จัดส่งในไทยก่อน",
    "单号": "หมายเลขนำส่งสินค้า",
    "[编辑备注]": "แก้ไขหมายเหตุ",
    "[复制单号]": "คัดลอกหมายเลขนำส่งสินค้า",
    "增值服务": "บริการเพิ่มเติม",
    "共铢(不含到付)": "ยอดรวม {amount} บาท (ไม่รวมยอดชำระเงินปลายทาง)",
    "等待包裹入库": "รอสินค้าเข้าโกดัง",
    "重量": "น้ำหนัก",
    "weight 公斤": "{weight} กก.",
    "长宽高(单位公分)": "กว้าง x ยาว x สูง (ซม.)",
    "体积": "ปริมาตร",
    "运输方式": "ช่องทางการขนส่ง",
    "计费方式": "วิธีการคำนวน",
    "折扣": "ส่วนลด",
    "转运费": "ค่าจัดส่งสินค้า",
    "铢": "บาท",
    "共铢(不含到付收费)": "ยอดรวม {amount} บาท (ไม่รวมยอดชำระเงินปลายทาง)",
    "收件地址": "ที่อยู่จัดส่ง",
    "登记单号，选择您需要的增值服务": "ลงทะเบียนหมายเลขใบนำส่งสินค้า",
    "运单号": "หมายเลขใบนำส่งสินค้า",
    "请输入运单号": "โปรดใส่หมายเลขใบนำส่งสินค้า",
    "陆运": "รถ",
    "海运": "เรือ",
    "海运不满30KG或0.2立方，按30KG收费": "ค่าขนส่งสำหรับพัสดุไม่ถึง 30 กก.หรือไม่ถึง 0.2 ลบม. จะคิดเป็นอัตราค่าบริการสำหรับพัสดุ 30 กก.",
    "保价": "ซื้อประกันพัสดุชิ้นนี้",
    "保价金额，保费": "จำนวนเงินรับประกัน:{declareAmount},ค่าประกัน:{insuranceFee}",
    "未保价运单按运费三倍赔偿": "พัสดุที่ไม่มีการซื้อประกันจะถูกชดใช้ค่าเสียหายเป็นเงินจำนวน 3 เท่าของค่าขนส่ง",
    "打木架": "ตีลังไม้",
    "到付快递费": "แจ้งโกดังจีนชำระเงินเมื่อเซ็นรับสินค้า",
    "按中国仓实付金额收费": "เก็บค่าบริการตามจำนวนจริงที่โกดังจีนได้ชำระออกไป",
    "中国仓退回": "ปฏิเสธการเซ็นรับพัสดุชิ้นนี้",
    "备注": "หมายเหตุ",
    "请输入备注": "โปรดใส่หมายเหตุ",
    "我已阅读并同意": "ฉันได้อ่านและยอมรับ",
    "《服务条款》": "เงื่อนไขการให้บริการ",
    "请填写运单号": "โปรดใส่หมายเลขใบนำส่งสินค้า",
    "单号最多输入60个字符": "ใส่ตัวอักษรและเลขของใบนำส่งสินค้าได้ไม่เกิน 60 ตัว",
    "单号只能输入字母、数字和横线,长度不超过60": "สามารถใส่ตัวอักษร ตัวเลขของหมายเลขใบนำส่งสินค้าได้ยาวไม่เกิน 60 ตัว",
    "请选择运输方式": "โปรดเลือกช่องทางการขนส่ง",
    "请先阅读并同意服务条款": "โปรดอ่านและยอมรับเงื่อนไขการให้บริการ",
    "登记成功": "ลงทะเบียนสำเร็จ",
    "count件包裹待支付": "พัสดุจำนวน {count} ชิ้นกำลังรอการชำระเงิน",
    "首重计费": "การคำนวนค่าบริการครั้งแรก",
    "折扣立减": "ส่วนลด",
    "请选择优惠券": "โปรดเลือกคูปอง",
    "无可用优惠券": "ไม่มีคูปอง",
    "实付": "ค่าจัดส่งที่ต้องชำระจริง",
    "支付": "ชำระ",
    "汇率": "อัตราแลกเปลี่ยน",
    "剩余": "คงเหลือ",
    "取消用券": "ยกเลิกคูปอง",
    "我们将尽快为您配送上门，感谢您的支持。": "พัสดุของคุณจะถูกจัดส่งไปยังที่อยู่ที่แจ้งไว้ทันที ขอบคุณที่สนับสนุนบริการของเรา",
    "如有问题请联系客服微信": "หากคุณมีปัญหาการใช้งาน โปรดติดต่อฝ่ายบริการลูกค้าได้ทาง Line Official",
    "[复制]": "[คัดลอก]",
    "前往查件": "กลับไปตรวจสอบพัสดุ",
    "下单成功": "เรียบร้อย",
    "请以英文或泰文填写收件人姓名和地址": "โปรดกรอกชื่อ-นามสกุลและที่อยู่จัดส่งเป็นภาษาไทยหรืออังกฤษเท่านั้น",
    "姓名": "ชื่อ",
    "邮编": "รหัสไปรษณีย์",
    "确 定": "ยืนยัน",
    "清 空": "ลบทั้งหมด",
    "请输入姓名": "โปรดใส่ชื่อ-นามสกุล",
    "姓名最多输入30个字符": "โปรดใส่ชื่อของคุณ ไม่เกิน 30 ตัวอักษร",
    "请输入电话": "โปรดใส่หมายเลขโทรศัพท์",
    "电话最少输入10个字符": "หมายเลขโทรศัพท์อย่างน้อยต้องมี 10 หลัก",
    "电话最多输入10个字符": "หมายเลขโทรศัพท์อย่างมากไม่เกิน 10 หลัก",
    "请输入详细地址": "โปรดใส่ที่อยู่ที่ละเอียด",
    "收货地址不能有汉字": "ที่อยู่จัดส่งไม่สามารถเป็นตัวอักษรภาษาจีนได้",
    "地址最少输入12个字符": "โปรดใส่ที่อยู่อย่างน้อย 12 ตัวอักษร",
    "地址最多输入300个字符": "โปรดใส่ที่อยู่อย่างมากไม่เกิน 30 ตัวอักษร",
    "请输入邮编": "โปรดใส่รหัสไปรษณีย์",
    "邮编不能有汉字": "ไม่สามารถใส่ตัวอักษรภาษาจีนเป็นรหัสไปรษณีย์",
    "邮编最少输入5个数字": "โปรดใส่รหัสไปรษณีย์อย่างน้อย 5 หลัก",
    "邮编最多输入5个数字": "โปรดใส่รหัสไปรษณีย์ไม่เกิน 5 หลัก",
    "新增": "เพิ่มแล้ว",
    "修改": "แก้ไข",
    "地址成功": "เพิ่มที่อยู่เรียบร้อยแล้ว",
    "地址失败": "ที่อยู่ผิด",
    "月月发券，四季常有": "แจกคูปองทุกต้นเดือน",
    "每月1号开始可领取月度优惠券": "อย่าลืมแวะเข้ามากดรับคูปองกันได้นะ",
    "可用券": "คูปองในกระเป๋า",
    "有效期至": "คูปองหมดอายุวันที่",
    "已过期": "หมดอายุ",
    "使用日期": "ใช่เมื่อวันที่",
    "已用券": "คูปองถูกใช้แล้ว",
    "保价金额": "จำนวนเงินประกัน",
    "请输入人民币金额": "โปรดใส่จำนวนเป็นเงินหยวน (RMB)",
    "保费": "เงินค่าประกัน",
    "按保价金额的%自动计算": "ระบบคำนวนเงินประกันอัตโนมัติที่ {rate}%",
    "取消保险": "ยกเลิกประกัน",
    "最高保价金额人民币": "เงินค่าประกันสูงสุดจำนวน {amount} หยวน (RMB)",
    "快递单号": "หมายเลขใบนำส่งสินค้า",
    "共count件包裹": "พัสดุรวม {count} ชิ้น",
    "收件信息": "ได้รับข้อมูลแล้ว",
    "备注最多不超过15个字符": "หมายเหตุไม่เกิน 15 ตัวอักษร",
    "编辑成功": "แก้ไขเรียบร้อย",
    "暂时没有找到相关运单": "ไม่พบใบนำส่งสินค้า",
    "请输入要搜索的快递单号": "โปรดใส่หมายเลขใบนำส่งเพื่อค้นหา",
    "包裹到达泰国后将通知您支付转运费并配送上门。": "หลังจากพัสดุทั้งหมดมาถึงไทย ทางระบบจะแจ้งให้ชำระค่าขนส่งและพัสดุทั้งหมดจะถูกจัดส่งออกไป",
    "继续预报运单": "กำลังประมาณค่าขนส่งโดยรวม",
    "待支付": "รอชำระ",
    "已支付": "ชำระแล้ว",
    "等待入仓": "รอเข้าโกดัง",
    "到达中转仓": "ถึงโกดังที่จีน",
    "泰国仓分拣中": "ระหว่างคัดแยก",
    "您已签收": "เซ็นรับแล้ว",
    "安排配送中": "เตรียมจัดส่ง",
    "待打包": "พร้อมแพ็ค",
    "已打包": "แพ็คแล้ว",
    "问题件": "พัสดุมีปัญหา",
    "按重量": "โดยน้ำหนัก",
    "按体积": "โดยปริมาตร",
    "请转账至": "โปรดโอนเงินไปยัง",
    "银行": "ธนาคาร",
    "账号": "หมายเลขบัญชี",
    "户名": "ชื่อบัญชี",
    "上传网银截图": "อัพโหลดสลิปการโอนเงิน",
    "金额": "จำนวน",
    "请输入充值金额": "โปรดใส่จำนวนเงิน",
    "账户余额": "ยอดเงินในบัญชี",
    "充值": "เติมเงิน",
    "用户余额充值": "ยอดการเติมเงินของลูกค้า",
    "运费返还": "คืนค่าจัดส่ง",
    "运单赔付": "ชดใช้ค่าจัดส่ง",
    "待审核": "รอการตรวจสอบเพื่อยืนยัน",
    "审核不通过": "การตรวจสอบมีปัญหา",
    "暂无记录": "ไม่มีข้อมูล",
    "余额": "ยอดคงเหลือ",
    "设为默认收件地址": "ตั้งเป็นที่อยู่จัดส่งหลัก",
    "编辑": "แก้ไข",
    "删除": "ลบ",
    "新增地址": "เพิ่มที่อยู่",
    "请选择收货地址": "โปรดเลือกที่อยู่จัดส่ง",
    "配送方式": "วิธีจัดส่ง",
    "请选择配送方式": "โปรดเลือกวิธีการจัดส่ง",
    "余额不足": "ยอดเงินไม่พอ",
    "登录中...": "กำลังเข้าสู่ระบบ",
    "小蜜蜂物流": "Tukship Express",
    "申请": "สมัคร",
    "获取你的昵称、头像": "ระบบจะตั้งชื่อผู้ใช้งานและเลือกใช้รูปภาพส่วนตัวตาม LINE",
    "Line个人信息": "ข้อมูลส่วนตัวของ LINE",
    "允许": "อนุญาต",
    "未保价的包裹按运费三倍赔偿（不超过物值），保价后按保价金额赔偿，贵重物品建议您预报运单并保价。易碎品或容易被压坏的包裹建议您打木架，否则无法保证货品安全。": "เพื่อความปลอดภัยของสินค้าที่แตกง่ายหรือสินค้าที่บอบบาง ทางบริษัทแนะนำคุณลูกค้าให้ตีลังไม้เพื่อป้องกันความเสียหายระหว่างจัดส่ง",
    "如何消费？>>": "ชำระค่าบริการยังไง",
    "本地派送预付费用": "ค่าจัดส่งในจีนที่ชำระล่วงหน้าโดยถูกชิพ",
    "的红包": "คูปองของ",
    "一起来分享": "มาแบ่งปันกันเถอะ",
    "已存入您的小蜜蜂账户余额，可直接消费": "เติมเงินในบัญชีเรียบร้อย สามารถชำระได้แล้ว",
    "您来迟了,红包已被抢光了!": "คุณมาไม่ทัน คูปองถูกหยิบไปหมดแล้ว",
    "count个红包已被领取": "คูปอง {count} ใบได้ถูกหยิบไปแล้ว",
    "乡/市/省/邮编": "ตำบล อำเภอ จังหวัด รหัสไปรษณีย์",
    "请选择省市区": "โปรดเลือกเขตและรหัสไปรษณีย์",
    "保险": "ประกันสินค้า",
    "国内快递费到付": "COD ในจีน",
    "运费计算器": "เครื่องคำนวณอัตราค่าบริการ",
    "尺寸": "ขนาด",
    "长": "ยาว",
    "宽": "กว้าง",
    "高": "สูง",
    "请输入重量": "โปรดใส่น้ำหนัก",
    "国际运费": "ค่าจัดส่งระหว่างประเทศ",
    "本地运费": "ค่าจัดส่งในประเทศ",
    "运费": "ค่าจัดส่ง",
    "计算": "คำนวณ",
    "请输入长度": "โปรดใส่ความยาว",
    "请输入宽度": "โปรดใส่ความกว้าง",
    "请输入高度": "โปรดใส่ความสูง",
    "费用": "ยอดชำระ",
    "货物类别": "ชนิดสินค้า",
    "重置": "ตั้งค่าใหม่",
    "计算结果": "ยอดสุทธิ",
    "打木架费": "ค่าตีลังไม้",
    "A类": "ประเภท A",
    "B类": "ประเภท B",
    "C类": "ประเภท C",
    "单位": "หน่วย",
    "查看价格表": "ตรวจสอบจากตารางราคา",
    "选填": "หากมีข้อมูล",
    "必填": "จำเป็น",
    "预估费用": "ค่าใช้จ่ายโดยประมาณ",
    "下拉即可刷新...": "เลื่อนลงเพื่อรีเฟรช",
    "释放即可刷新...": "ปล่อยเพื่อรีเฟรช",
    "地址管理": "จัดการที่อยู่",
    "地址簿": "สมุดที่อยู่",
    "运单详情": "รายละเอียดใบขนส่ง",
    "保险服务": "ประกันสินค้า",
    "我的优惠券": "คูปองของฉัน",
    "我的余额": "ยอดเงินของฉัน",
    "小蜜蜂红包": "คูปองเงินสดของ Tukship",
    "默认": "ค่าเริ่มต้น",
    "选为收件地址": "เลือกเป็นที่อยู่จัดส่ง",

    "没有": "ไม่มี",
    "恭喜获得amount泰铢红包": "ยินดีด้วย คุณได้รับคูปองเงินสด {amount} บาท",
    "分享给小伙伴，大家一起抢": "แชร์คูปองกัน",
    "分享到Line领取": "แชร์ไปยัง LINE เพื่อรับ",
    "下次再说": "ไว้โอกาสหน้า",
    "自提": "เข้ารับสินค้า",
    "小蜜蜂": "Maximus",
    "JD": "JD",
    "Deliveree": "Deliveree",
    "您已经登记此包裹，可在「查件」页面搜索单号": "พัสดุชิ้นนี้ได้ถูกบันทึกเข้าในระบบแล้ว คุณสามารถไปที่ [ตรวจสอบ] เพื่อค้นหาพัสดุชิ้นนี้",

    "昵称": "昵称",

    "商品详情": "รายละเอียดสินค้า",
    "下单": "สั่งซื้อ",
    "收银台": "จุดคิดเงิน",
    "登录": "ลงทะเบียน",
    "订单": "คำสั่งซื้อ",
    "HOME": "หน้าแรก",
    "CART": "ตะกร้าสินค้า",
    "PROFILE": "บัญชีของฉัน",
    "Remove": "ลบทิ้ง",
    "Submit": "ยืนยัน",
    "Total": "ทั้งหมด",
    "确定从购物车移除吗？": "ยืนยันที่จะย้ายออกจากตะกร้า?",
    "商品已从购物车移除": "สินค้าถูกย้ายออกจากตะกร้าแล้ว",
    "My Orders": "คำสั่งซื้อของฉัน",
    "All orders": "คำสั่งซื้อทั้งหมด",
    "To Pay": "รอชำระ",
    "To be confirmed": "รอยืนยัน",
    "To Ship": "รอจัดส่ง",
    "To Receive": "รอรับ",
    "Return & Cancellations": "ตีกลับ / ยกเลิก",
    "Addresses": "ที่อยู่",
    "Link with  Line / Facebook": "เชื่อมต่อกับ LINE / Facebook",
    "修改地址成功": "แก้ไขที่อยู่สำเร็จ",
    "新增地址成功": "เพิ่มที่อยู่สำเร็จ",
    "Add Address": "เพิ่มที่อยู่",
    "确定删除地址吗？": "ยืนยันที่จะลบที่อยู่นี้?",
    "地址已删除": "ลบที่อยู่สำเร็จ",
    "No addresses": "ไม่มีที่อยู่",
    "All": "ทั้งหมด",
    "Total Shipping Price": "ค่าจัดส่งทั้งหมด",
    "Total Amount": "จำนวนเงินทั้งสิ้น",
    "Add new address": "เพิ่มที่อยู่ใหม่",
    "Order Details": "รายละเอียดคำสั่งซื้อ",
    "Pay Now": "ชำระทันที",
    "BUY NOW": "สั่งซื้อทันที",
    "ADD TO CART": "ใส่ตะกร้าสินค้า",
    "库存": "จำนวนสินค้า",
    "Quantity": "จำนวน",
    "stock insufficient": "สินค้าไม่เพียงพอ",
    "add cart success": "เพิ่มเข้าตะกร้าสำเร็จ",
    "输入关键字搜索": "ค้นหาสินค้าทันที",
    "Recent Search": "ประวัติการค้นหา",
    "sold": "ขายแล้ว",
    "取消等待": "ยกเลิก",
    "Sync failed": "เชื่อมต่อล้มเหลว",
    "价格": "ราคา",
    "起批量": "จำนวนขั้นต่ำ",
    "amount件起批": "ขายส่งขั้นต่ำ {amount} ชิ้น",
    "件_": "ชิ้น",
    "请先选择收件地址": "โปรดเลือกที่อยู่จัดส่ง",
    "优惠": "ส่วนลด",
    "Cart is empty": "ตะกร้าว่างเปล่า",
    "前往登录": "เข้าสู่ระบบ",
    "电话号码必须以0开头10位数": "หมายเลขโทรศัพท์ควรเริ่มต้นด้วยเลข 0 และมี 10 หลัก",
    "请输入手机号": "โปรดกรอกหลายเลขโทรศัพท์",
    "请输入验证码": "โปรดกรอกรหัส OTP",
    "获取验证码": "รับรหัส OTP",
    "counts后重新发送": "ส่งอีกครั้งหลัง {count} วินาที",
    "商品不存在": "ไม่มีสินค้าชินนี้",
    "短信验证码不正确或已失效": "รหัสผิดหรือล้มเหลว",
    "Cancel Order": "ยกเลิกคำสั่งซื้อ",
    "Subtotal": "ยอดรวม",
    "Freight": "ค่าขนส่ง",
    "Calculating": "กำลังคำนวณ",
    "Are you sure you want to cancel order": "ยืนยันการยกเลิกคำสั่งซื้อ {orderName}?",
    "overseas": "ต่างประเทศ",
    "Pay for all orders": "ชำระค่าสินค้าทั้งหมด",
    "by land transpotation": "ขนส่งทางรถ",
    "by sea transpotation": "ขนส่งทางเรือ",
    "This is an estimate and actual amounts may vary.": "ค่าขนส่งสุทธิอาจจะมีการเปลี่ยนแปลง",
    "orders amount": "{amount} คำสั่งซื้อ",
    "Pay": "ชำระเงิน",
    "Tracking": "ติดตามสถานะ",
    "You have amount cross-border orders that will be shipped when the freight is paid. Please select transportation type to calculate the freight.": "คุณมีคำสั่งซื้อต่างประเทศจำนวน {amount} รายการที่กำลังจะจัดส่งเมื่อได้มีการชำระค่าขนส่งเข้ามาแล้ว กรุณาเลือกประเภทการขนส่งเพื่อคำนวณค่าขนส่ง",
    "See Rates >>": "ตารางราคา >>",
    "Select all amount cross-border orders": "เลือกคำสั่งซื้อทั้งหมด {amount} รายการ",
    "Weight": "น้ำหนัก",
    "Volume": "ปริมาตร",
    "Waiting for the goods to arrive at the warehouse": "รอรับสินค้าเข้าโกดัง",
    "Total Weight": "น้ำหนักรวม",
    "Total Volume": "ปริมาตรรวม",
    "Total Freight": "ค่าจัดส่งทั้งสิ้น",
    "Pay Freight": "วิธีชำระเงิน",
    "Please select transportation type": "โปรดเลือกวิธีจัดส่ง",
    "This order has been delivered to our warehouse. The logistic fee for send it back to the supplier will be deducted from the refund. Are you sure you want to cancel?": "คำสั่งซื้อนี้ได้ถูกจัดส่งไปยังคลังสินค้าของเราแล้ว ค่าจัดส่งสำหรับการตีกลับไปยังซัพพลายเออร์จะถูกหักออกจากยอดการคืนเงิน คุณแน่ใจหรือว่าต้องการจะยกเลิก?",
    "Transportation type": "วิธีจัดส่ง",
    "Please Select": "โปรดเลือก",
    "Slip uploaded successfully": "อัพโหลดสลิปสำเร็จ",
    "If you have any questions, feel free to contact our customer service via Line Offical @beericher": "หากคุณมีคำถาม โปรดติดต่อฝ่ายลูกค้าสัมพันธ์ของเราผ่าน Line Official @beericher",
    "Home Page": "หน้าแรก",
    "Order No": "หมายเลขคำสั่งซื้อ",
    "This is a cross-border order": "คำสั่งซื้อจากต่างประเทศ",
    "Cancel": "ยกเลิก",
    "Refund policy>>": "เงื่อนไขการคืนเงิน>>",
    "订单取消成功": "การยกเลิกคำสั่งซื้อสำเร็จ",
    "Please transfer and upload slip": "โปรดโอนชำระและอัพโหลดสลิป",
    "Bank slip": "สลิปการโอน",
    "Bank": "ธนาคาร",
    "Account No.": "เลขบัญชี",
    "Account Name": "ชื่อบัญชี",
    "Amount": "ยอดคงเหลือ",
    "See sample slip": "ดูตัวอย่างสลิป",
    "ratings": "{ratings} คะแนน",
    "Shipping": "กำลังจัดส่ง",
    "The supplier ships the goods to our warehouse in China": "ผู้ขายจัดส่งสินค้าไปยังโกดังของเราที่จีน",
    "You pay the cross border freight before a cross border logistic company ships the goods to Thailand.": "คุณชำระค่าขนส่งสินค้าต่างประเทศก่อนที่บริษัทขนส่งสินค้าลจะจัดส่งสินค้ามายังประเทศไทย",
    "Click to Estimate freight": "คำนวณค่าจัดส่งโดยประมาณ",
    "A local express company delivers the goods to your address": "ขนส่งเอกชนจัดส่งสินค้าไปยังที่อยู่ของคุณ",
    "See Shipping Details >>": "ข้อมูลการจัดส่ง >>",
    "Cancelled": "ยกเลิกแล้ว",
    "Clck to Estimate freight and Transit Time": "กดเพื่อคำนาณค่าขนส่งและระยะเวลาจัดส่ง",
    "Minimum order quantity": "จำนวนขั้นต่ำ",
    "Refundable": "สามารถคืนเงินได้",
    "Refund": "คืนเงิน",
    "Refunded": "คืนเงินแล้ว",
    "Refunding": "กำลังคืนเงิน",
    "Additional Cross-border Freight": "ค่าขนส่งข้ามประเทศเพิ่มเติม",
    "amount cross-border orders will be shipped when the freight is paid": "{amount} คำสั่งซื้อระหว่างประเทศจะถูกจัดส่งหลังจากมีการชำระเงินเข้ามาแล้ว",
    "Deliver to door": "จัดส่งถึงบ้าน",
    "Cross-border": "ข้ามพรรมแดน",
    "notice to pay": "หมายเหตุ: สำหรับการจัดส่งจากต่างประเทศ คุณจะต้องชำระค่าขนส่งระหว่างประเทศเพิ่มเติม เราจะแจ้งให้คุณทราบเมื่อผู้ให้บริการขนส่งได้รับสินค้าและได้คำนวณราคาขนส่งตามน้ำหนักหรือปริมาตรของสินค้า",
    "See details": "รายละเอียด",
    "全选": "เลือกทั้งหมด",
    "orders shipped together": "คำสั่งซื้อทั้งหมด {amount} รายการได้ถูกจัดส่งพร้อมกัน",
    "Arrived at the transite warehouse": "จัดส่งถึงคลังสินค้า",
    "Cross border Freight Paid": "ชำระค่าขนส่งระหว่างประเทศแล้ว",
    "Ready to ship to Thailand": "พร้อมจัดส่งไปไทย",
    "Fulfilled by the supplier": "ผู้ขายจัดเตรียมสินค้าแล้ว",
    "Order placed": "ส่งคำสั่งซื้อให้ผู้ขายแล้ว",
    "orders have been shipped together": "คำสั่งซื้อทั้งหมด {amount} รายการได้ถูกจัดส่งพร้อมกัน",
    "Waybills": "ใบนำส่ง",
    "Total packages": "สินค้าทั้งหมด {amount} ชิ้น",
    "Orders": "คำสั่งซื้อ",
    "Completed": "เสร็จสิ้น",
    "Ordered successfully": "สั่งซื้อเรียบร้อยแล้ว",
    "Order cancelled successfully": "ยกเลิกการสั่งซื้อเรียบร้อยแล้ว",
    "You will receive your refund upon receipt of the returned goods by the supplier. The shipping cost to send the goods back to the supplier will be deducted from your refund.  If you have any questions, please contact us via Line Offical @beericher": "คุณจะได้รับเงินค่าสินค้าคืนเมื่อได้ผู้ขายได้รับสินค้าที่ส่งตีกลับ ค่าจัดส่งสินค้าที่ตีกลับไปกลับไปยังผู้ขายจะถูกหักออกจากยอดเงินคืนของคุณ หากคุณมีคำถามใดๆ โปรดติดต่อเราได้ทาง Line Official @beericher",
    "You will receive your refund within 3 business days. If you have any questions, please contact us via Line Offical @beericher": "คุณจะได้รับเงินคืนภายใน 3 วันทำการ หากคุณมีคำถามใด ๆ โปรดติดต่อเราทาง Line Official @beericher",
    "Order failed": "คำสั่งซื้อล้มเหลว",
    "Please login": "โปรดเข้าสู่ระบบ",
    "You have successfully logged in": "คุณได้เข้าสู่ระบบเรียบร้อย",
    "Help center": "ศูนย์ช่วยเหลือ",
    "What is BeeRicher": "BeeRicher คืออะไร",
    "How to order?": "สั่งสินค้าอย่างไร?",
    "How to pay?": "วิธีการชำระเงิน?",
    "How to handle short shipment and wrong shipment?": "นโยบายจัดการคำสั่งซื้อที่มีปัญหา?",
    "How to contact the cross-border logistic service provider?": "ติดต่อผู้ให้บริการขนส่งระหว่างประเทศอย่างไร?",
    "How to make a claim for damaged or lost shipment?": "จะเรียกร้องค่าชดใช้สำหรับความเสียหายจากการขนส่งได้อย่างไร?",
    "Cancel, Refund & Return Policy": "นโยบายการยกเลิกและคืนสินค้า",
    "Five differences between BeeRicher and other sourcing agents": "จุดเด่นของ BeeRicher",
    "hc-Shipping": "กระบวนการและเรทขนส่ง",
    "未登录": "โปรดเข้าสู่ระบบ",
    "SEARCH HISTORY": "ประวัติการค้นหา",
    "正在查找商品，请等待一会": "กำลังค้นหาสินค้า โปรดรอซักครู่",
    "正在更新数据...": "กำลังอัพเดทข้อมูล...",
    "返回首页": "กลับไปหน้าหลัก",
    "该商品已下架": "รายการนี้ยกเลิกการขายแล้ว",
    "缺货": "ขาดสต็อก",
    "退出登录": "ออกจากระบบ",
    "到达泰国仓": "จัดส่งถึงประเทศไทย",
    "已取消": "ยกเลิกแล้ว",
    "已揽收": "รอเข้ารับ",
    "已退回泰国仓": "ตีกลับคลังสินค้าในไทย",
    "出库中": "กำลังจัดส่งออกจากคลัง",
    "您正在寻找的商品": "ผลิตภัณฑ์ที่คุณกำลังมองหา"
}